import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"

import theme from "../components/ui/theme"

import Layout from "../components/ui/layout"
import { TableBody } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    honoContainer: {
        marginTop: "5rem",
        width: "100%"
    },
    table: {
        border: "2px solid #000",
        width: "47.5rem",
        marginLeft: 0
    },
    mandatRow: {
        backgroundColor: theme.palette.primary.main
    },
    tableContainer: {
        width: '47.5rem'
    },
    afterTableContainer: {
        width: "47rem"
    },
    honoComponentsContainer: {
        width: "47rem"
    }
}))

const Honoraires = () => {
    const classes = useStyles()

    const baremes = [
        { pnv: "Inférieur à 50 000 €", ms: "Forfait fixe de 3 000 €", me: "Forfait fixe de 2 500 €" },
        { pnv: "Entre 50 001 et 250 000 €", ms: "7%", me: "6,5%" },
        { pnv: "Entre 250 001 et 450 000 €", ms: "6%", me: "5,5%" },
        { pnv: "Entre 450 001 et 650 000 €", ms: "5%", me: "4,5%" },
        { pnv: "Au-delà de 651 000 €", ms: "4%", me: "3,5%" }
    ]

    const afterTable = [
        "À noter que le Cabinet applique 10% de réduction sur ses honoraires à partir de deux biens confiés simultanément.",
        "Des aménagements dégressifs pourront être apportés en fonction de notre intervention et du prix des biens."
    ]

    const honoComponents = [
        "La prise des éléments techniques du bien",
        "L’estimation détaillée du bien prenant en compte l’analyse par rapport au marché",
        "Le rapport d’estimation comprenant une proposition commerciale",
        "La rédaction du mandat de vente et sa signature",
        "La rédaction du dossier et des fiches techniques",
        "La réalisation de photos de qualité par un photographe professionnel",
        "Les publicités internet, presse papier, etc.",
        "Le renouvellement des textes, annonces et des photos",
        "La relance des clients acquéreurs, prospects de notre cabinet",
        "La réception des appels, l’accueil des clients de passage et le traitement des mails, comprenant la découverte des prospects et présentation des biens",
        "Les visites et revisites",
        "Le reporting vendeurs après chaque visite",
        "Le suivi et le conseil du vendeur",
        "Le suivi et le conseil de l’acquéreur",
        "La récupération des documents éventuellement nécessaires auprès des administrations, notaire, syndic",
        "L’analyse des possibilités de construction et d’extension auprès des services de l’urbanisme",
        "La rédaction de la lettre d’intention d’achat",
        "L’analyse de la situation financière des acquéreurs",
        "L’analyse de l’environnement juridique des parties",
        "L’analyse des diagnostics immobiliers",
        "La rédaction du compromis de vente",
        "L’envoi du projet du compromis aux notaires",
        "La signature du compromis",
        "L’envoi du dossier à toutes les parties",
        "Le suivi et la paye du délai de rétractation",
        "Le suivi du dossier et des conditions suspensives",
        "L’organisation du RDV technique de bienvenue préalable",
        "Les relevés de compteurs",
        "L’identification des clés",
        "Les relevés de compteurs",
        "L’assistance à la signature de l’acte",
        "L’adhésion FNAIM (service juridique, documents type)",
        "La formation et la veille professionnelle",
        "La garantie Responsabilité civile professionnelle",
        "Le renouvellement des cartes professionnelles",
        "Le SAV",
        "L’infrastructure de l’agence (bureaux, loyers, électricité, informatique, maintenance…)",
        "Les logiciels métiers",
        "Les frais de déplacement / les frais postaux / la comptabilité",
        "L’impôt sur la société et les autres taxes",
    ]

    return (
        <Layout>
            <Grid container alignContent="center" classes={{ root: classes.honoContainer }} direction="column">
                <Grid item>
                    <Typography variant="h1">
                        Baremes d'honoraires
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        En vigueur à compter du 1er janvier 2022.
                    </Typography>
                </Grid>
                <Grid item container classes={{ root: classes.tableContainer }} alignItems="flex-end" direction="column">
                    <Grid item>
                        <Table classes={{ root: classes.table }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '20rem' }} align="center">
                                        <Typography variant="h1">
                                            PRIX NET VENDEUR
                                        </Typography>
                                        <Typography>
                                            à la charge de l'acquéreur
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <Typography variant="h1">
                                            % DES HONORAIRES TTC*
                                        </Typography>
                                        <Typography>
                                            à la charge de l'acquéreur
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow classes={{ root: classes.mandatRow }}>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Typography style={{ color: "#fff" }}>Mandat simple</Typography>
                                    </TableCell>
                                    <TableCell style={{ color: "#fff" }}>Mandat exclusif</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {baremes.map(item => (
                                    <TableRow key={item.pnv}>
                                        <TableCell style={{ width: "10rem" }}>
                                            <Typography>{item.pnv}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>{item.ms}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>{item.me}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item>
                        <Typography>*selon le taux de TVA en vigueur.</Typography>
                    </Grid>
                </Grid>
                <Grid item classes={{ root: classes.afterTableContainer }} container>
                    <Grid item>
                        <Typography>
                            <ul>
                                {
                                    afterTable.map(item => (
                                        <li key={item}>{item}</li>
                                    ))
                                }
                            </ul>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item classes={{root: classes.honoComponentsContainer}} container>
                    <Grid item>
                        <Typography variant="h1">
                            CE QUE COMPRENNENT LES HONORAIRES DE VENTE
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            <ul>
                                {honoComponents.map((item, i) => (
                                    <li key={i}>{item}</li>
                                ))}
                            </ul>
                        </Typography>
                        </Grid>
                </Grid>
            </Grid>
        </Layout>
    )
}
export default Honoraires